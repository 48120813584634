import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Default from '../components/default';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <Default article hideTitle pageTitle='About Me'>
    {children}
  </Default>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Hi! I'm a large piranha.`}</h2>
    <p>{`I am a muthafucka. Y'all best be watching it, 'cause I be up in your internal organs n shit if you
ain't careful. Bitch, my homies and I gon eat yo ass.`}</p>
    <img {...{
      "src": "http://4.bp.blogspot.com/--JrndM2jPvQ/T38nph8UAyI/AAAAAAAAHl0/u-c6NGFFZ8Y/",
      "alt": "Look at me",
      "title": "Look"
    }}></img>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      